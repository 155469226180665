import { useEffect, useState } from "react";

export const useIsPrinting = () => {
  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("print");

    const handlePrintChange = (e: MediaQueryListEvent) => {
      setIsPrinting(e.matches);
    };

    // Add event listener
    mediaQuery.addEventListener("change", handlePrintChange);

    return () => {
      mediaQuery.removeEventListener("change", handlePrintChange);
    };
  }, []);

  return isPrinting;
};
